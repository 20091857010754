$(document).ready(function(){
    // Add minus icon for collapse element which is open by default
    $(".collapse.show").each(function(){
        $(this).prev('.accordion-header').find('.fa').addClass('fa-minus').removeClass('fa-plus');
    });

    //Change icon on click accordion button
    $('.accordion-container').find('button').on('click', function(){
        $('.accordion-container').find('.accordion-header').find('.fa').removeClass('fa-minus').addClass('fa-plus');
        if (!$(this).hasClass('show')){
            $(this).closest(".accordion-header").find('.fa').removeClass('fa-plus').addClass('fa-minus');
        }
    });
});


